

label {
    margin: 20px;
    display: block;
    color: black;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px;

    /* media query */
    @media (max-width: 768px) {
      flex-direction: column;
    }
}

.initate-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 50px;
    /* border-radius: 5px; */
    margin-top: 50px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
      min-width: fit-content;
    }
}

.initiate-button {
    background-color:#1c7ed6;
    color: white;
    border: none;
    padding: 15px;
    cursor: pointer;
    border-radius: 5px;
    font-size: large;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
}

.caption-container {
    padding: 10px;
    color: white;
    text-align: left;
    font-weight: bold;
}

.qa-container {
    min-width: 500px;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    margin: 20px 0;
    border-bottom: 1px solid #f0f0f0;
}

.question {
    color: black;
    text-align: justify;
    margin-bottom: 5px;
}

.typed-out{
    /* overflow: hidden; */
    /* display: flex; */
    /* white-space: nowrap; */
    /* animation: typing 2s forwards; */
    font-size: 22px;
    margin-left: 15px;
    /* width: 0; */
}

.orange-text {
  color: #FF9933;
}

.text-light {
  color: #F8FDFF;
}

.border-light {
  border: 1px solid #F8FDFF;
}

.border-dark {
  border: 1px solid #939DA0;
}

.text-dark {
  color: #000000;
}

.rule {
  margin: 5px 0;
}

.checkbox {
  opacity: 0;
  position: absolute;
}

.checkbox-label {
  background-color: #111;
  width: 40px;
  height: 15px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox-label .ball {
  background-color: #fff;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

.checkbox:checked + .checkbox-label .ball {
  transform: translateX(24px);
}

@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}

@keyframes quiet {
    25%{
      transform: scaleY(.6);
    }
    50%{
      transform: scaleY(.4);
    }
    75%{
      transform: scaleY(.8);
    }
  }
  
  @keyframes normal {
    25%{
      transform: scaleY(1);
    }
    50%{
      transform: scaleY(.4);
    }
    75%{
      transform: scaleY(.6);
    }
  }
  @keyframes loud {
    25%{
      transform: scaleY(1);
    }
    50%{
      transform: scaleY(.4);
    }
    75%{
      transform: scaleY(1.2);
    }
  }
  
  .boxContainer{
    display: flex;
    justify-content: space-between;
    height: 64px;
    --boxSize: 6px;
    --gutter: 4px;
    width: calc((var(--boxSize) + var(--gutter)) * 5);
  }
  
  .box{
    transform: scaleY(.4);
    height: 100%;
    width: var(--boxSize);
    background: #FF9933;
    animation-duration: 1.2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    border-radius: 8px;
  }
  
  .box1{
    animation-name: quiet;
  }
  
  .box2{
    animation-name: normal;
  }
  
  .box3{
    animation-name: quiet;
  }
  
  .box4{
    animation-name: loud;
  }
  
  .box5{
    animation-name: quiet;
  }